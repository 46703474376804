/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.Card {
    background-image: url("/public/cardBack.jpg") ;
    background-repeat: repeat;
    background-size: 18vh auto;
    margin: 0 auto;
    background-color: #444;
    color: #fff;
    border-radius: 5%;
    padding: .5vh;
    font-size: 150%;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    width: 12vh;
  width: calc(var(--vh, 1vh) * 12);
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.BackOfCard {
    margin: 0 auto;
    background-color: #444;
    color: #fff;
    border-radius: 5%;
    padding: .5vh;
    font-size: 150%;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    width: 12vh;
  width: calc(var(--vh, 1vh) * 12);
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.hoverScale {
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;
}

.hoverScale:hover {
    -webkit-transform:scale(1.1);
        -ms-transform:scale(1.1);
            transform:scale(1.1)
}
.pickUpBadge .pickUpBadgeVerbage {
    visibility: hidden;
}

body:has(.pickUpBadge:hover) .pickUpBadgeVerbage {
    visibility: visible;
}

.pickUpBadgeBig .pickUpBadgeVerbage {
    visibility: hidden;
}

body:has(.pickUpBadgeBig:hover) .pickUpBadgeVerbage {
    visibility: visible;
}

.CardBig {
    background-image: url("/public/cardBack.jpg") ;
    background-repeat: repeat;
    background-size: 48vh auto;
    margin: 0 auto;
    background-color: #444;
    color: #fff;
    border-radius: 5%;
    padding: 1.5vh;
    font-size: 150%;
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.CardcardAttacking {
    background-image: url("/public/cardBack.jpg") ;
    background-repeat: repeat;
    background-size: 18vh auto;
    z-index: 1250;
    margin: 0 auto;
    background-color: #444;
    color: #fff;
    border-radius: 5%;
    padding: .5vh;
    font-size: 150%;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    width: 12vh;
  width: calc(var(--vh, 1vh) * 12);
    -webkit-animation: cardAttack 2s linear calc(1);
            animation: cardAttack 2s linear calc(1);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.CardcardOppAttacking {
    background-image: url("/public/cardBack.jpg") ;
    background-repeat: repeat;
    background-size: 18vh auto;
    margin: 0 auto;
    background-color: #444;
    color: #fff;
    border-radius: 5%;
    padding: .5vh;
    font-size: 150%;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    width: 12vh;
  width: calc(var(--vh, 1vh) * 12);
    -webkit-animation: cardOppAttack 2s linear calc(1);
            animation: cardOppAttack 2s linear calc(1);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
@-webkit-keyframes cardAttack {
    0% {
        -webkit-transform: rotateX(0) translateY(0);
                transform: rotateX(0) translateY(0)
    }
    40% {
        -webkit-transform: rotateX(20deg) translateY(0);
                transform: rotateX(20deg) translateY(0)
    }
    50% {
        -webkit-transform: translateY(-20vh) rotateX(-50deg);
                transform: translateY(-20vh) rotateX(-50deg)
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0)
    }
}
@keyframes cardAttack {
    0% {
        -webkit-transform: rotateX(0) translateY(0);
                transform: rotateX(0) translateY(0)
    }
    40% {
        -webkit-transform: rotateX(20deg) translateY(0);
                transform: rotateX(20deg) translateY(0)
    }
    50% {
        -webkit-transform: translateY(-20vh) rotateX(-50deg);
                transform: translateY(-20vh) rotateX(-50deg)
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0)
    }
}

@-webkit-keyframes cardOppAttack {
    0% {
        -webkit-transform: rotateX(0) translateY(0);
                transform: rotateX(0) translateY(0)
    }
    40% {
        -webkit-transform: rotateX(-20deg) translateY(0);
                transform: rotateX(-20deg) translateY(0)
    }
    50% {
        -webkit-transform: translateY(20vh) rotateX(50deg);
                transform: translateY(20vh) rotateX(50deg)
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0)
    }
}

@keyframes cardOppAttack {
    0% {
        -webkit-transform: rotateX(0) translateY(0);
                transform: rotateX(0) translateY(0)
    }
    40% {
        -webkit-transform: rotateX(-20deg) translateY(0);
                transform: rotateX(-20deg) translateY(0)
    }
    50% {
        -webkit-transform: translateY(20vh) rotateX(50deg);
                transform: translateY(20vh) rotateX(50deg)
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0)
    }
}

.CardcardAttackingcardDamaged {
    background-image: url("/public/cardBack.jpg") ;
    background-repeat: repeat;
    background-size: 18vh auto;
    z-index: 1250;
    margin: 0 auto;
    background-color: #444;
    color: #fff;
    border-radius: 5%;
    padding: .5vh;
    font-size: 150%;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    width: 12vh;
  width: calc(var(--vh, 1vh) * 12);
    -webkit-animation: cardAttackDam 2s linear calc(1);
            animation: cardAttackDam 2s linear calc(1);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.CardcardOppAttackingcardOppDamaged {
    background-image: url("/public/cardBack.jpg") ;
    background-repeat: repeat;
    background-size: 18vh auto;
    margin: 0 auto;
    background-color: #444;
    color: #fff;
    border-radius: 5%;
    padding: .5vh;
    font-size: 150%;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    width: 12vh;
  width: calc(var(--vh, 1vh) * 12);
    -webkit-animation: cardOppAttackDam 2s linear calc(1);
            animation: cardOppAttackDam 2s linear calc(1);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
@-webkit-keyframes cardAttackDam {
    0% {
        -webkit-transform: rotateX(0) translateY(0);
                transform: rotateX(0) translateY(0)
    }
    40% {
        -webkit-transform: rotateX(20deg) translateY(0);
                transform: rotateX(20deg) translateY(0);
        opacity: 1;
    }
    50% {
        -webkit-transform: translateY(-20vh) rotateX(-50deg);
                transform: translateY(-20vh) rotateX(-50deg)
    }
    55% {
        -webkit-transform: rotateX(20deg) translateY(-18vh) translateZ(2vh) rotateY(2deg) rotateZ(2deg);
                transform: rotateX(20deg) translateY(-18vh) translateZ(2vh) rotateY(2deg) rotateZ(2deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0);
        opacity: 1;
    }
}
@keyframes cardAttackDam {
    0% {
        -webkit-transform: rotateX(0) translateY(0);
                transform: rotateX(0) translateY(0)
    }
    40% {
        -webkit-transform: rotateX(20deg) translateY(0);
                transform: rotateX(20deg) translateY(0);
        opacity: 1;
    }
    50% {
        -webkit-transform: translateY(-20vh) rotateX(-50deg);
                transform: translateY(-20vh) rotateX(-50deg)
    }
    55% {
        -webkit-transform: rotateX(20deg) translateY(-18vh) translateZ(2vh) rotateY(2deg) rotateZ(2deg);
                transform: rotateX(20deg) translateY(-18vh) translateZ(2vh) rotateY(2deg) rotateZ(2deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes cardOppAttackDam {
    0% {
        -webkit-transform: rotateX(0) translateY(0);
                transform: rotateX(0) translateY(0)
    }
    40% {
        -webkit-transform: rotateX(-20deg) translateY(0);
                transform: rotateX(-20deg) translateY(0);
        opacity: 1;
    }
    50% {
        -webkit-transform: translateY(20vh) rotateX(50deg);
                transform: translateY(20vh) rotateX(50deg)
    }
    55% {
        -webkit-transform: rotateX(-20deg) translateY(18vh)  translateZ(2vh) rotateY(-2deg) rotateZ(-2deg);
                transform: rotateX(-20deg) translateY(18vh)  translateZ(2vh) rotateY(-2deg) rotateZ(-2deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0);
        opacity: 1;
    }
}

@keyframes cardOppAttackDam {
    0% {
        -webkit-transform: rotateX(0) translateY(0);
                transform: rotateX(0) translateY(0)
    }
    40% {
        -webkit-transform: rotateX(-20deg) translateY(0);
                transform: rotateX(-20deg) translateY(0);
        opacity: 1;
    }
    50% {
        -webkit-transform: translateY(20vh) rotateX(50deg);
                transform: translateY(20vh) rotateX(50deg)
    }
    55% {
        -webkit-transform: rotateX(-20deg) translateY(18vh)  translateZ(2vh) rotateY(-2deg) rotateZ(-2deg);
                transform: rotateX(-20deg) translateY(18vh)  translateZ(2vh) rotateY(-2deg) rotateZ(-2deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0);
        opacity: 1;
    }
}

.CardcardDamaged {
    background-image: url("/public/cardBack.jpg") ;
    background-repeat: repeat;
    background-size: 18vh auto;
    margin: 0 auto;
    background-color: #444;
    color: #fff;
    border-radius: 5%;
    padding: .5vh;
    font-size: 150%;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    width: 12vh;
  width: calc(var(--vh, 1vh) * 12);
    -webkit-animation: cardDam 2s linear calc(1);
            animation: cardDam 2s linear calc(1);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.CardcardOppDamaged {
    background-image: url("/public/cardBack.jpg") ;
    background-repeat: repeat;
    background-size: 18vh auto;
    margin: 0 auto;
    background-color: #444;
    color: #fff;
    border-radius: 5%;
    padding: .5vh;
    font-size: 150%;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    width: 12vh;
  width: calc(var(--vh, 1vh) * 12);
    -webkit-animation: cardOppDam 2s linear calc(1);
            animation: cardOppDam 2s linear calc(1);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
@-webkit-keyframes cardDam {
    0% {
        -webkit-transform: rotateX(0) translateY(0);
                transform: rotateX(0) translateY(0);
    }
    45% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0);
        opacity: 1;
    }
    55% {
        -webkit-transform: rotateX(20deg) translateZ(2vh) rotateY(2deg) rotateZ(2deg);
                transform: rotateX(20deg) translateZ(2vh) rotateY(2deg) rotateZ(2deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0);
        opacity: 1;
    }
}
@keyframes cardDam {
    0% {
        -webkit-transform: rotateX(0) translateY(0);
                transform: rotateX(0) translateY(0);
    }
    45% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0);
        opacity: 1;
    }
    55% {
        -webkit-transform: rotateX(20deg) translateZ(2vh) rotateY(2deg) rotateZ(2deg);
                transform: rotateX(20deg) translateZ(2vh) rotateY(2deg) rotateZ(2deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes cardOppDam {
    0% {
        -webkit-transform: rotateX(0) translateY(0);
                transform: rotateX(0) translateY(0);
    }
    45% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0);
        opacity: 1;
    }
    55% {
        -webkit-transform: rotateX(-20deg) translateZ(2vh) rotateY(-2deg) rotateZ(-2deg);
                transform: rotateX(-20deg) translateZ(2vh) rotateY(-2deg) rotateZ(-2deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0);
        opacity: 1;
    }
}

@keyframes cardOppDam {
    0% {
        -webkit-transform: rotateX(0) translateY(0);
                transform: rotateX(0) translateY(0);
    }
    45% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0);
        opacity: 1;
    }
    55% {
        -webkit-transform: rotateX(-20deg) translateZ(2vh) rotateY(-2deg) rotateZ(-2deg);
                transform: rotateX(-20deg) translateZ(2vh) rotateY(-2deg) rotateZ(-2deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
                transform: translateY(0) rotateX(0);
        opacity: 1;
    }
}

.CardSunkcardAttacking {
    background-image: url("/public/cardBack.jpg") ;
    background-repeat: repeat;
    background-size: 18vh auto;
    z-index: 1250;
    margin: 0 auto;
    background-color: #444;
    color: #fff;
    border-radius: 5%;
    padding: .5vh;
    font-size: 150%;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    width: 12vh;
  width: calc(var(--vh, 1vh) * 12);
    opacity: 0;
    -webkit-animation: cardDeathAtt 4s linear;
            animation: cardDeathAtt 4s linear;
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@-webkit-keyframes cardDeathAtt {
    0% {
        opacity: 1;
        -webkit-transform: perspective(500vh) translateY(0) rotateX(0) translateY(0);
                transform: perspective(500vh) translateY(0) rotateX(0) translateY(0);
    }
    20% {
        opacity: 1;
        -webkit-transform: perspective(500vh) rotateX(20deg) translateY(0);
                transform: perspective(500vh) rotateX(20deg) translateY(0);
    }
    25% {
        opacity: 1;
        -webkit-transform: perspective(500vh) translateY(-20vh) rotateX(50deg);
                transform: perspective(500vh) translateY(-20vh) rotateX(50deg);
    }
    50% {
        -webkit-transform: perspective(500vh) translateY(0) rotateX(0);
                transform: perspective(500vh) translateY(0) rotateX(0);
        opacity: 1;
    }
    100% {
        background-color: unset;
        opacity: 0;
        -webkit-transform: perspective(500vh) translateY(15vh)  rotateX(-55deg);
                transform: perspective(500vh) translateY(15vh)  rotateX(-55deg);
    }
}

@keyframes cardDeathAtt {
    0% {
        opacity: 1;
        -webkit-transform: perspective(500vh) translateY(0) rotateX(0) translateY(0);
                transform: perspective(500vh) translateY(0) rotateX(0) translateY(0);
    }
    20% {
        opacity: 1;
        -webkit-transform: perspective(500vh) rotateX(20deg) translateY(0);
                transform: perspective(500vh) rotateX(20deg) translateY(0);
    }
    25% {
        opacity: 1;
        -webkit-transform: perspective(500vh) translateY(-20vh) rotateX(50deg);
                transform: perspective(500vh) translateY(-20vh) rotateX(50deg);
    }
    50% {
        -webkit-transform: perspective(500vh) translateY(0) rotateX(0);
                transform: perspective(500vh) translateY(0) rotateX(0);
        opacity: 1;
    }
    100% {
        background-color: unset;
        opacity: 0;
        -webkit-transform: perspective(500vh) translateY(15vh)  rotateX(-55deg);
                transform: perspective(500vh) translateY(15vh)  rotateX(-55deg);
    }
}


.CardSunkcardOppAttacking {
    background-image: url("/public/cardBack.jpg") ;
    background-repeat: repeat;
    background-size: 18vh auto;
    z-index: 1250;
    margin: 0 auto;
    background-color: #444;
    color: #fff;
    border-radius: 5%;
    padding: .5vh;
    font-size: 150%;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    width: 12vh;
  width: calc(var(--vh, 1vh) * 12);
    opacity: 0;
    -webkit-animation: cardDeathOppAtt 4s linear;
            animation: cardDeathOppAtt 4s linear;
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@-webkit-keyframes cardDeathOppAtt {
    0% {
        opacity: 1;
        -webkit-transform: perspective(500vh) translateY(0) rotateX(0) translateY(0);
                transform: perspective(500vh) translateY(0) rotateX(0) translateY(0);
    }
    20% {
        opacity: 1;
        -webkit-transform: perspective(500vh) rotateX(-20deg) translateY(0);
                transform: perspective(500vh) rotateX(-20deg) translateY(0);
    }
    25% {
        opacity: 1;
        -webkit-transform: perspective(500vh) translateY(20vh) rotateX(50deg);
                transform: perspective(500vh) translateY(20vh) rotateX(50deg);
    }
    50% {
        -webkit-transform: perspective(500vh) translateY(0) rotateX(0);
                transform: perspective(500vh) translateY(0) rotateX(0);
        opacity: 1;
    }
    100% {
        background-color: unset;
        opacity: 0;
        -webkit-transform: perspective(500vh) translateY(15vh)  rotateX(-55deg);
                transform: perspective(500vh) translateY(15vh)  rotateX(-55deg);
    }
}

@keyframes cardDeathOppAtt {
    0% {
        opacity: 1;
        -webkit-transform: perspective(500vh) translateY(0) rotateX(0) translateY(0);
                transform: perspective(500vh) translateY(0) rotateX(0) translateY(0);
    }
    20% {
        opacity: 1;
        -webkit-transform: perspective(500vh) rotateX(-20deg) translateY(0);
                transform: perspective(500vh) rotateX(-20deg) translateY(0);
    }
    25% {
        opacity: 1;
        -webkit-transform: perspective(500vh) translateY(20vh) rotateX(50deg);
                transform: perspective(500vh) translateY(20vh) rotateX(50deg);
    }
    50% {
        -webkit-transform: perspective(500vh) translateY(0) rotateX(0);
                transform: perspective(500vh) translateY(0) rotateX(0);
        opacity: 1;
    }
    100% {
        background-color: unset;
        opacity: 0;
        -webkit-transform: perspective(500vh) translateY(15vh)  rotateX(-55deg);
                transform: perspective(500vh) translateY(15vh)  rotateX(-55deg);
    }
}
.CardSunk {
    background-image: url("/public/cardBack.jpg") ;
    background-repeat: repeat;
    background-size: 18vh auto;
    z-index: 1250;
    margin: 0 auto;
    background-color: #444;
    color: #fff;
    border-radius: 5%;
    padding: .5vh;
    font-size: 150%;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    width: 12vh;
  width: calc(var(--vh, 1vh) * 12);
    opacity: 0;
    -webkit-animation: cardDeath 4s linear;
            animation: cardDeath 4s linear;
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@-webkit-keyframes cardDeath {
    0% {
        opacity: 1;
        -webkit-transform: perspective(500vh) translateY(0) rotateX(0);
                transform: perspective(500vh) translateY(0) rotateX(0);
    }
    25% {
        opacity: 1;
        -webkit-transform: perspective(500vh) translateY(0) rotateX(0);
                transform: perspective(500vh) translateY(0) rotateX(0);
    }
    100% {
        background-color: unset;
        opacity: 0;
        -webkit-transform: perspective(500vh) translateY(15vh)  rotateX(-55deg);
                transform: perspective(500vh) translateY(15vh)  rotateX(-55deg);
    }
}

@keyframes cardDeath {
    0% {
        opacity: 1;
        -webkit-transform: perspective(500vh) translateY(0) rotateX(0);
                transform: perspective(500vh) translateY(0) rotateX(0);
    }
    25% {
        opacity: 1;
        -webkit-transform: perspective(500vh) translateY(0) rotateX(0);
                transform: perspective(500vh) translateY(0) rotateX(0);
    }
    100% {
        background-color: unset;
        opacity: 0;
        -webkit-transform: perspective(500vh) translateY(15vh)  rotateX(-55deg);
                transform: perspective(500vh) translateY(15vh)  rotateX(-55deg);
    }
}

.Card:hover {
    z-index: 600;
    -webkit-transform: scale(1.4) translateY(-5%);
        -ms-transform: scale(1.4) translateY(-5%);
            transform: scale(1.4) translateY(-5%);
}

.HoverIndex:hover {
    z-index: 600;
}

.CardFace {
    position: relative;
    z-index: inherit;
    height: 100%;
    width: 100%;
    border-radius: 5%;
    background-color: #ececec;
    opacity: .5;
}

.ie8 .CardFaceBig {
    zoom: 2; /* IE */
    position: relative;
    z-index: inherit;
    height: 100%;
    width: 100%;
    border-radius: 5%;
    background-color: #ececec;
    opacity: .5;
}
.pickUpBadgeBig, .AttibutesBig,.StickersBig {
    -moz-transform: scale(2); /* Firefox */
    -moz-transform-origin: 0 0;
    -o-transform: scale(2); /* Opera */
    -o-transform-origin: 0 0;
    -webkit-transform: scale(2); /* Safari And Chrome */
    -webkit-transform-origin: 0 0;
    transform: scale(2); /* Standard Property */
    transform-origin: 0 0;  /* Standard Property */
}
.CardFaceBig {
    -moz-transform: scale(2); /* Firefox */
    -moz-transform-origin: 0 0;
    -o-transform: scale(2); /* Opera */
    -o-transform-origin: 0 0;
    -webkit-transform: scale(2); /* Safari And Chrome */
    -webkit-transform-origin: 0 0;
    transform: scale(2); /* Standard Property */
    transform-origin: 0 0;  /* Standard Property */
    position: relative;
    z-index: inherit;
    height: 50%;
    width: 50%;
    border-radius: 5%;
    background-color: #ececec;
    opacity: .5;
}


.Title {
    -webkit-transition: all .15s;
    -o-transition: all .15s;
    transition: all .15s;
    font-size: 1.5vh;
    font-weight: 800;
    position: relative;
    top:.5vh
}
.Type {
    -webkit-transition: all .15s;
    -o-transition: all .15s;
    transition: all .15s;
    font-size: 1vh;
    top:.5vh;
    font-weight: 600;
    position: relative;
}
.Weight {
    opacity: 1;
    font-size: 1vh;
    width: 2vh;
  width: calc(var(--vh, 1vh) * 2);
    height: 2vh;
  height: calc(var(--vh, 1vh) * 2);
    position: relative;
    top:-2.5vh;
    left:.75vh;
    border-radius: 50%;
    background-color: #FFD700FF;
}
.WeightCircle {
    -ms-grid-column-align: center;
        justify-self: center;
    font-size: 1.5vh;
    position: relative;
    top:0vh;
}
.Attack {
    opacity: 1;
    font-size: 1vh;
    width: 2vh;
  width: calc(var(--vh, 1vh) * 2);
    height: 2vh;
  height: calc(var(--vh, 1vh) * 2);
    position: relative;
    top:-2.5vh;
    left:.75vh;
    border-radius: 50%;
    background-color: #CD7F32;
}
.AttackCircle {
    -ms-grid-column-align: center;
        justify-self: center;
    font-size: 1.5vh;
    position: relative;
    top:0vh;
}
.Health {
    opacity: 1;
    width: 2vh;
  width: calc(var(--vh, 1vh) * 2);
    height: 2vh;
  height: calc(var(--vh, 1vh) * 2);
    position: relative;
    top:-2.5vh;
    left:.75vh;
    border-radius: 50%;
    background-color: #C0C0C0;
}
.HealthCircle {
    -ms-grid-column-align: center;
        justify-self: center;
    font-size: 1.5vh;
    position: relative;
    top:0vh;
}
.Description {
    width: 11.5vh;
width: calc(var(--vh, 1vh) * 11.5);
    height: 12vh;
  height: calc(var(--vh, 1vh) * 12);
    -webkit-transition: all .15s;
    -o-transition: all .15s;
    transition: all .15s;
    font-size: 1.2vh;
    position: relative;
    left:.25vh;
    top:3vh;
}
.Unit {
    font-size: 1vh;
    position: relative;
    top:-2.5vh;
    left:-.5vh;
}

.Hand {
    z-index: 501;
    height: 24vh;
    height: calc(var(--vh, 1vh) * 24);
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    transition: top .5s;
}

.OpponentHand {
    z-index: 0;
    width: 100vw;
    height: 18vh;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    top:9vh;
    -webkit-transform: perspective(500vh) translateZ(-100vh) rotateX(-55deg);
            transform: perspective(500vh) translateZ(-100vh) rotateX(-55deg);
    overflow: visible;
}

.skewCardPersp {
    -webkit-animation:placeCard 1s ease-in-out calc(1);
            animation:placeCard 1s ease-in-out calc(1);
    opacity:1;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;

}
.skewCardPerspSink {
    -webkit-animation:cardSink 5s linear calc(1);
            animation:sink 5s linear calc(1);
    -webkit-transform:rotateX(0deg) ;
            transform:rotateX(0deg) ;
    opacity:0
}
.SubCard {
    position: relative;
}
.visyButtonCard {
    opacity: 1;
}
.invisibleButtonCard {
    opacity: 0;
}
.cardButton {
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}
.BrokenPlank {
    -webkit-animation: break 2s linear calc(1);
            animation: break 2s linear calc(1);
}

@-webkit-keyframes break {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes break {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.ArenaCards {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    overflow: hidden !important;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
@-webkit-keyframes placeCard {
    from{
        -webkit-transform: rotateX(60deg) translateZ(250px);
                transform: rotateX(60deg) translateZ(250px);
    }
    to{
        -webkit-transform: rotateX(0deg) translateZ(0);
                transform: rotateX(0deg) translateZ(0);
    }
}
@keyframes placeCard {
    from{
        -webkit-transform: rotateX(60deg) translateZ(250px);
                transform: rotateX(60deg) translateZ(250px);
    }
    to{
        -webkit-transform: rotateX(0deg) translateZ(0);
                transform: rotateX(0deg) translateZ(0);
    }
}
@-webkit-keyframes sink {
    0% {
        -webkit-transform: rotateX(0deg) rotateY(0deg);
                transform: rotateX(0deg) rotateY(0deg);
        -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
                box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
        height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
        opacity: 1;
    }
    10% {
        -webkit-transform: rotateX(0deg) rotateY(0deg);
                transform: rotateX(0deg) rotateY(0deg);
        -webkit-box-shadow: 0px 12.5vh 0px #010b1377;
                box-shadow: 0px 12.5vh 0px #010b1377;
        height: 12.5vh;
  height: calc(var(--vh, 1vh) * 12.5);
    }
    20% {
        -webkit-transform:  translateY(0) (60deg) rotateY(0deg);
                transform:  translateY(0) (60deg) rotateY(0deg);
        -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
                box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
        height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    }
    30% {
        -webkit-transform: translateY(100px) rotateX(60deg) rotateY(0deg);
                transform: translateY(100px) rotateX(60deg) rotateY(0deg);
        -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
                box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
        height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
        opacity: 1;
    }
    80% {
        -webkit-transform: translateY(200px) rotateX(60deg) rotateY(0deg);
                transform: translateY(200px) rotateX(60deg) rotateY(0deg);
        -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
                box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
        height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0deg) rotateY(0deg);
                transform: translateY(0) rotateX(0deg) rotateY(0deg);
        -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
                box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
        height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
        opacity: 0;
        }
}
@keyframes sink {
    0% {
        -webkit-transform: rotateX(0deg) rotateY(0deg);
                transform: rotateX(0deg) rotateY(0deg);
        height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
        opacity: 1;
    }
    10% {
        -webkit-transform: rotateX(0deg) rotateY(0deg);
                transform: rotateX(0deg) rotateY(0deg);
        height: 12.5vh;
  height: calc(var(--vh, 1vh) * 12.5);
    }
    20% {
        -webkit-transform:  translateY(0) (60deg) rotateY(0deg);
                transform:  translateY(0) (60deg) rotateY(0deg);
        height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    }
    30% {
        -webkit-transform: translateY(100px) rotateX(60deg) rotateY(0deg);
                transform: translateY(100px) rotateX(60deg) rotateY(0deg);
        height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
        opacity: 1;
    }
    80% {
        -webkit-transform: translateY(200px) rotateX(60deg) rotateY(0deg);
                transform: translateY(200px) rotateX(60deg) rotateY(0deg);
        height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0deg) rotateY(0deg);
                transform: translateY(0) rotateX(0deg) rotateY(0deg);
        height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
        opacity: 0;
        }
}
.ArenaPlankSink {
    -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
            box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
    background-color: #966F33;
    width: 144vh;
    height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    left:0;
    right:0;
    margin: auto;
    position: absolute;
    top: 45%;
    display:-ms-grid;
    display:grid;
    grid-template-columns:repeat(8,12.5%);
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    justify-items: center;
    -ms-flex-line-pack: space-evenly;
        align-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-animation: rotatePlankSink 5s linear  calc(1);
            animation: rotatePlankSink 5s linear  calc(1);
}

.ArenaPlankOpponentSink {
    -webkit-transform: translateY(-40vh);
        -ms-transform: translateY(-40vh);
            transform: translateY(-40vh);
    -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
            box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
    background-color: #966F33;
    width: 144vh;
    height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    left:0;
    right:0;
    margin: auto;
    position: absolute;
    top: 45%;
    display:-ms-grid;
    display:grid;
    
    grid-template-columns:repeat(8,12.5%);
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    justify-items: center;
    -ms-flex-line-pack: space-evenly;
        align-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-animation: rotatePlankOppSink 5s linear calc(1);
            animation: rotatePlankOppSink 5s linear calc(1);
}
@keyframes rotateShip {
    0% {
        height: 56vh
    }
    25% {
        height: 61vh
    }
    50% {
        height: 56vh
    }
    75% {

        height: 61vh
    }
    100% {
        height: 56vh
    }
}
.ShipFrontOpp {
    position: absolute;
    width: 144vh;
    height: 25vh;
    left:-22.9vh;
    clip-path: polygon(16.66% 0%, 16.66%  100%, 12% 95%, 04% 52% ,3% 45%, 4% 40%, 12% 5%);
    background-image:url("/public/decktex.jpg");
    background-size: 18vh auto;
    background-repeat: repeat;
    background-color: #966F33;
}
.ShipBodyBackOpp {
    position: absolute;
    top:0vh;
    width: 154.1vh;
    height: 25vh;
    left:.3vh;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    background-image:url("/public/decktex.jpg");
    background-size: 18vh auto;
    background-repeat: repeat;
    background-color: #966F33;
    z-index: 0;
}
.ShipBackOpp {
    background-image:url("/public/browntex.jpg");
    background-repeat: repeat;
    background-size: 18vh auto;
    position: absolute;
    top:-14vh;
    z-index: 0;
    width: 260.1vh;
    height: 35vh;
    left:-36.5vh;
    clip-path: polygon(86% 38%, 85% 20%, 83% 4%, 81% 0%, 73% 2%, 71% 5vh, 69% 8vh, 67% 10vh, 16% 10vh, 13% 30%, 8% 55%, 6% 64%, 4.1% 65%, 1.1% 62%, 1.5% 67%, 7.1% 88%, 20% 100%, 12% 20vh, 80% 100%, 83% 90%,85% 75%, 86% calc(16vh - 8%));
    background-color: #3f3b2a;
}
.UpperShipDeckOpp {
    position: absolute;
    top:-10vh;
    width: 45.1vh;
    height: 25vh;
    left:153vh;
    clip-path: polygon(69% 50%, 65% 25%, 60% 10%, 50% 0%, 0% 0%, 0% 100%, 50% 100%, 60% 90%,65% 75%, 68% 60%);
    background-image:url("/public/decktex.jpg");
    background-size: 18vh auto;
    background-repeat: repeat;
    background-color: #966F33;
    background-color: #966F33;
}

.ShipBodyOpp {
    transition: transform 1s;
    animation: rotateShip 10s linear infinite;
    position: absolute;
    background-image:url("/public/browntex.jpg");
    background-repeat: repeat;
    background-size: 18vh auto;
    width: 212vh;
    height: 50vh;
    right:-188vh;
    /* clip-path: polygon(83.33% 50%, 88% 47.5%,96% 30% ,100% 25%, 95% 40%, 92% 51%, 90% 60%, 88% 70%,82% 100%,0% 100%,0% 50%); */
    clip-path: polygon(16.66% 24.9vh, 12% 23.75vh,4% 15vh ,0% 12.5vh, 5% 40%, 8% 51%, 10% 60%, 12% 70%,18% 100%,88% 100%, 90% 91%, 92% 80%, 94% 34vh,96% 24vh, 99% 0,97% calc(20vh - 30%),95% calc(22vh - 30%), 92% calc(24vh - 30%), 84% calc(27vh - 30%), 82% calc(32vh - 30%),79% calc(37vh - 30%), 74% 24.9vh);
    background-color: #3f3b2a;
}
.ShipContain {
    position: absolute;
    left: 0;
    top:0;
    z-index: 0;
    width: 0;
    height: 0;
}
.ShipContainBack {
    position: absolute;
    left: 0;
    top:0vh;
    width: 0;
    height: 0;
}
.ShipBodyBack {
    position: absolute;
    top:0vh;
    width: 154.1vh;
    height: 25vh;
    left:-13vh;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    background-image:url("/public/decktex.jpg");
    background-size: 18vh auto;
    background-repeat: repeat;
    background-color: #966F33;
    z-index: 0;
}
.ShipBodyBackDoorOpp {
    background-image:url("/public/browntex.jpg");
    background-repeat: repeat;
    background-size: 18vh auto;
    position: absolute;
    top:-14vh;
    z-index: 1;
    width: 260.1vh;
    height: 35vh;
    left:-36.5vh;
    clip-path: polygon(73.25% 5%, 71% 40%, 71% 100%, 73.25% 80%);
}
.ShipBodyBackDoor {
    background-image:url("/public/bluebrowntex.jpg");
    background-repeat: repeat;
    background-size: 22vh auto;
    position: absolute;
    top:-14vh;
    z-index: 1;
    width: 260.1vh;
    height: 35vh;
    left:-78.5vh;
    clip-path: polygon(26.75% 5%, 29% 40%, 29% 100%, 26.75% 80%);
}
.ShipBack {
    background-image:url("/public/bluebrowntex.jpg");
    background-repeat: repeat;
    background-size: 22vh auto;
    position: absolute;
    top:-14vh;
    z-index: 0;
    width: 260.1vh;
    height: 35vh;
    left:-78.5vh;
    clip-path: polygon(14% 38%, 15% 20%, 17% 4%, 19% 0%, 27% 2%, 29% 5vh, 31% 8vh, 33% 10vh, 84% 10vh, 87% 30%, 92% 55%, 94% 64%, 95.9% 65%, 98.9% 62%, 98.5% 67%, 92.9% 88%, 80% 100%, 88% 20vh, 20% 100%, 17% 90%,15% 75%, 14% calc(16vh - 8%));
    background-color: #3f3b2a;
}
.UpperShipDeck {
    position: absolute;
    top:-10vh;
    width: 45.1vh;
    height: 25vh;
    left:-53vh;
    clip-path: polygon(31% 50%, 35% 25%, 40% 10%, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 40% 90%,35% 75%, 32% 60%);
    background-image:url("/public/decktex.jpg");
    background-size: 18vh auto;
    background-repeat: repeat;
    background-color: #966F33;
    background-color: #966F33;
}
.ShipFront {
    position: absolute;
    width: 168vh;
    height: 25vh;
    z-index: 0;
    right:-168.9vh;
    background-image:url("/public/decktex.jpg");
    background-size: 18vh auto;
    background-repeat: repeat;
    background-color: #966F33;
    clip-path: polygon(83.33% 0%, 83.33%  100%, 88% 95%, 96% 52% ,97% 45%, 96% 40%, 88% 5%);
    background-color: #966F33;
}
.ShipBody {
    transition: transform 1s;
    animation: rotateShip 10s linear infinite;
    position: absolute;
    background-image:url("/public/bluebrowntex.jpg");
    background-repeat: repeat;
    background-size: 22vh auto;
    width: 212vh;
    height: 50vh;
    right:-169vh;
    /* clip-path: polygon(83.33% 50%, 88% 47.5%,96% 30% ,100% 25%, 95% 40%, 92% 51%, 90% 60%, 88% 70%,82% 100%,0% 100%,0% 50%); */
    clip-path: polygon(83.33% 24.9vh, 88% 23.75vh,96% 15vh ,100% 12.5vh, 95% 40%, 92% 51%, 90% 60%, 88% 70%,82% 100%,12% 100%, 10% 91%, 8% 80%, 6% 34vh,4% 24vh, 1% 0,3% calc(20vh - 30%),5% calc(22vh - 30%), 8% calc(24vh - 30%), 16% calc(27vh - 30%), 18% calc(32vh - 30%),21% calc(37vh - 30%), 26% 24.9vh);
    background-color: #3f3b2a;
}


.ArenaPlank {
    z-index: 5;
    background-color: #966F33;
    width: 144vh;
    height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    left:0;
    right:0;
    margin: auto;
    position: absolute;
    top: 45%;
    display:-ms-grid;
    display:grid;
    
    grid-template-columns:repeat(8,12.5%);
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    justify-items: center;
    -ms-flex-line-pack: space-evenly;
        align-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-animation: rotatePlank 10s linear infinite;
            animation: rotatePlank 10s linear infinite;
}

.ArenaPlankOpponent {
    -webkit-transform: translateY(-40vh);
        -ms-transform: translateY(-40vh);
            transform: translateY(-40vh);
    -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
            box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
    background-color: #966F33;
    width: 144vh;
    height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    left:0;
    right:0;
    margin: auto;
    position: absolute;
    top: 45%;
    display:-ms-grid;
    display:grid;
    
    grid-template-columns:repeat(8,12.5%);
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    justify-items: center;
    -ms-flex-line-pack: space-evenly;
        align-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-animation: rotatePlankOpp 10s -3s linear infinite;
            animation: rotatePlankOpp 10s -3s linear infinite;
}


@-webkit-keyframes rotatePlankSink {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
              box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
      height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    }
    10% {
        -webkit-transform:  rotateX(0) rotateX(-20deg) translateY(10vh);
                transform:  rotateX(0) rotateX(-20deg) translateY(10vh);
        -webkit-box-shadow: 0px 12.5vh 0px #010b1377;
                box-shadow: 0px 12.5vh 0px #010b1377;
        height: 12.5vh;
  height: calc(var(--vh, 1vh) * 12.5);
      }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
              box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
      height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    }
}


@keyframes rotatePlankSink {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
              box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
      height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    }
    10% {
        -webkit-transform:  rotateX(0) rotateX(-20deg) translateY(10vh);
                transform:  rotateX(0) rotateX(-20deg) translateY(10vh);
        -webkit-box-shadow: 0px 12.5vh 0px #010b1377;
                box-shadow: 0px 12.5vh 0px #010b1377;
        height: 12.5vh;
  height: calc(var(--vh, 1vh) * 12.5);
      }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
              box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
      height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    }
}

@-webkit-keyframes rotatePlankOppSink {
    0% {
      -webkit-transform: translateY(-40vh);
              transform: translateY(-40vh);
      -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
              box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
      height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    }
    10% {
        -webkit-transform: rotateX(-20deg) translateY(-30vh);
                transform: rotateX(-20deg) translateY(-30vh);
        -webkit-box-shadow: 0px 12.5vh 0px #010b1377;
                box-shadow: 0px 12.5vh 0px #010b1377;
        height: 12.5vh;
  height: calc(var(--vh, 1vh) * 12.5);
      }
    100% {
      -webkit-transform: translateY(-40vh);
              transform: translateY(-40vh);
      -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
              box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
      height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    }
}

@keyframes rotatePlankOppSink {
    0% {
      -webkit-transform: translateY(-40vh);
              transform: translateY(-40vh);
      -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
              box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
      height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    }
    10% {
        -webkit-transform: rotateX(-20deg) translateY(-30vh);
                transform: rotateX(-20deg) translateY(-30vh);
        -webkit-box-shadow: 0px 12.5vh 0px #010b1377;
                box-shadow: 0px 12.5vh 0px #010b1377;
        height: 12.5vh;
  height: calc(var(--vh, 1vh) * 12.5);
      }
    100% {
      -webkit-transform: translateY(-40vh);
              transform: translateY(-40vh);
      -webkit-box-shadow: 0px 2vh #010b1377;
-webkit-box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
              box-shadow: 0px 2vh #010b1377;
box-shadow: 0px  calc(var(--vh, 1vh) * 2) #010b1377;
      height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
    }
}

@-webkit-keyframes rotatePlank {
    0% {
      -webkit-transform: rotateX(0deg) rotateY(0deg);
              transform: rotateX(0deg) rotateY(0deg);
    }
    25% {
      -webkit-transform: rotateX(-20deg) rotateY(0deg);
              transform: rotateX(-20deg) rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateX(0deg) rotateY(-2deg);
              transform: rotateX(0deg) rotateY(-2deg);
    }
    75% {
      -webkit-transform: rotateX(20deg) rotateY(2deg);
              transform: rotateX(20deg) rotateY(2deg);
    }
    100% {
      -webkit-transform: rotateX(0deg) rotateY(0deg);
              transform: rotateX(0deg) rotateY(0deg);
    }
}

@keyframes rotatePlank {
    0% {
      -webkit-transform: rotateX(0deg) rotateY(0deg);
              transform: rotateX(0deg) rotateY(0deg);
    }
    25% {
      -webkit-transform: rotateX(-20deg) rotateY(0deg);
              transform: rotateX(-20deg) rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateX(0deg) rotateY(-2deg);
              transform: rotateX(0deg) rotateY(-2deg);
    }
    75% {
      -webkit-transform: rotateX(20deg) rotateY(2deg);
              transform: rotateX(20deg) rotateY(2deg);
    }
    100% {
      -webkit-transform: rotateX(0deg) rotateY(0deg);
              transform: rotateX(0deg) rotateY(0deg);
    }
}

@-webkit-keyframes rotatePlankOpp {
    0% {
        -webkit-transform: rotateX(0deg) rotateY(0deg) translateY(-40vh);
                transform: rotateX(0deg) rotateY(0deg) translateY(-40vh);
      }
      25% {
        -webkit-transform: rotateX(20deg) rotateY(2deg) translateY(-40vh);
                transform: rotateX(20deg) rotateY(2deg) translateY(-40vh);
      }
      50% {
        -webkit-transform: rotateX(0deg) rotateY(0deg) translateY(-40vh);
                transform: rotateX(0deg) rotateY(0deg) translateY(-40vh);
      }
      75% {
        -webkit-transform: rotateX(-10deg) rotateY(-2deg) translateY(-40vh);
                transform: rotateX(-10deg) rotateY(-2deg) translateY(-40vh);
      }
      100% {
        -webkit-transform: rotateX(0deg) rotateY(0deg) translateY(-40vh);
                transform: rotateX(0deg) rotateY(0deg) translateY(-40vh);
      }
}

@keyframes rotatePlankOpp {
    0% {
        -webkit-transform: rotateX(0deg) rotateY(0deg) translateY(-40vh);
                transform: rotateX(0deg) rotateY(0deg) translateY(-40vh);
      }
      25% {
        -webkit-transform: rotateX(20deg) rotateY(2deg) translateY(-40vh);
                transform: rotateX(20deg) rotateY(2deg) translateY(-40vh);
      }
      50% {
        -webkit-transform: rotateX(0deg) rotateY(0deg) translateY(-40vh);
                transform: rotateX(0deg) rotateY(0deg) translateY(-40vh);
      }
      75% {
        -webkit-transform: rotateX(-10deg) rotateY(-2deg) translateY(-40vh);
                transform: rotateX(-10deg) rotateY(-2deg) translateY(-40vh);
      }
      100% {
        -webkit-transform: rotateX(0deg) rotateY(0deg) translateY(-40vh);
                transform: rotateX(0deg) rotateY(0deg) translateY(-40vh);
      }
}

.bell, .bell-border, .btn-bell {
    z-index: 6;

    border-radius: 50%;

}

.bell {
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;

    position: absolute;


    -webkit-box-shadow: -1px 2px 10px #999;

            box-shadow: -1px 2px 10px #999;

    -webkit-animation-duration: 2s;

            animation-duration: 2s;

    -webkit-animation-iteration-count: infinite;

            animation-iteration-count: infinite;

}


.bell-border {

    position: absolute;

    -webkit-animation-name: bord-pop;

            animation-name: bord-pop;

    -webkit-animation-duration: 2s;

            animation-duration: 2s;

    -webkit-animation-iteration-count: infinite;

            animation-iteration-count: infinite;

}

.btn-bell {
    position: absolute;



    z-index: 9;

    color: white;

    font-size: 2vh;

    -webkit-animation-duration: 2s;

            animation-duration: 2s;

    -webkit-animation-iteration-count: infinite;

            animation-iteration-count: infinite;

}

.exit, .exit-border, .btn-exit {
    z-index: 6;

    border-radius: 50%;

}

.exit {
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;

    position: absolute;


    margin: auto;

    -webkit-box-shadow: -1px 2px 10px #999;

            box-shadow: -1px 2px 10px #999;

    -webkit-animation-duration: 2s;

            animation-duration: 2s;

    -webkit-animation-iteration-count: infinite;

            animation-iteration-count: infinite;

}


.bell-exit {

    position: absolute;

    -webkit-animation-name: bord-pop;

            animation-name: bord-pop;

    -webkit-animation-duration: 2s;

            animation-duration: 2s;

    -webkit-animation-iteration-count: infinite;

            animation-iteration-count: infinite;

}

.btn-exit {
    position: absolute;

    top: 1.25rem;
    left:21.5px;

    z-index: 9;

    color: white;

    font-size: 2vh;

    -webkit-animation-duration: 2s;

            animation-duration: 2s;

    -webkit-animation-iteration-count: infinite;

            animation-iteration-count: infinite;

}


.shop, .shop-border, .btn-shop {
    z-index: 6;

    border-radius: 50%;

}

.shop:hover {
    -webkit-transform: scale(1.4);
        -ms-transform: scale(1.4);
            transform: scale(1.4)
}

.exit:hover {
    -webkit-transform: scale(1.4);
        -ms-transform: scale(1.4);
            transform: scale(1.4)
}

.user:hover {
    -webkit-transform: scale(1.4);
        -ms-transform: scale(1.4);
            transform: scale(1.4)
}

.bell:hover {
    -webkit-transform: scale(1.4);
        -ms-transform: scale(1.4);
            transform: scale(1.4)
}
.InfoButton {
    position: absolute;
    top:1.125vh;
    right:.25vh;
}

.ZoomShopHeader {
    position: relative;
    top:11.5vh;
    -moz-transform: scale(.9); /* Firefox */
    -moz-transform-origin: 50% 0;
    -o-transform: scale(.9); /* Opera */
    -o-transform-origin: 50% 0;
    -webkit-transform: scale(.9); /* Safari And Chrome */
    -webkit-transform-origin: 50% 0;
    transform: scale(.9); /* Standard Property */
    transform-origin: 50% 0;  /* Standard Property */
}

.ZoomShopCards {
    position: relative;
    top:6vh;
    left:0;
    right:0;
    margin: auto;
    -moz-transform: scale(.95); /* Firefox */
    -moz-transform-origin: 0 0;
    -o-transform: scale(.95); /* Opera */
    -o-transform-origin: 0 0;
    -webkit-transform: scale(.95); /* Safari And Chrome */
    -webkit-transform-origin: 0 0;
    transform: scale(.95); /* Standard Property */
    transform-origin: -500% 0;  /* Standard Property */
}

.shop {
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;

    position: absolute;

    -webkit-box-shadow: -1px 2px 10px #999;

            box-shadow: -1px 2px 10px #999;

    -webkit-animation-duration: 2s;

            animation-duration: 2s;

    -webkit-animation-iteration-count: infinite;

            animation-iteration-count: infinite;

}

.btn-shop {
    position: absolute;

    top: 1.25rem;
    left:21.5px;

    z-index: 9;

    color: white;

    font-size: 2vh;

    -webkit-animation-duration: 2s;

            animation-duration: 2s;

    -webkit-animation-iteration-count: infinite;

            animation-iteration-count: infinite;

}

.NumPurchasable {
    width:2vh;
    height:2vh;
    position: absolute;
    left:4.5vh;
    font-size: 1.8vh;
    line-height: 1.8vh;
    outline:.5vh solid #de5454;
    background-color: #ffbbbb;
    border-radius: 50%;
}

.user, .user-border, .btn-user {
    z-index: 6;

    border-radius: 50%;

}

.user {
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;

    position: absolute;

    top: 6vh;
  top: calc(var(--vh, 1vh) * 6);

    -webkit-box-shadow: -1px 2px 10px #999;

            box-shadow: -1px 2px 10px #999;

    -webkit-animation-duration: 2s;

            animation-duration: 2s;

    -webkit-animation-iteration-count: infinite;

            animation-iteration-count: infinite;

}

.btn-user {
    position: absolute;

    top: 1.25rem;
    left:21.5px;

    z-index: 9;

    color: white;

    font-size: 2vh;

    -webkit-animation-duration: 2s;

            animation-duration: 2s;

    -webkit-animation-iteration-count: infinite;

            animation-iteration-count: infinite;

}

.CardShopBackgroundInner {
    position: absolute;
    width: 75vw;
    height: 75vh;
  height: calc(var(--vh, 1vh) * 75);
    background-color: #ffffffbb;
    border-radius: 5vh;
    -webkit-transition: opacity .5s;
    -o-transition: opacity .5s;
    transition: opacity .5s;
    top:12.5vh;
    left:12.5vw;
    z-index: 521;
    overflow: hidden;
}
.CardShopBackgroundCards {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    position: absolute;
    width: 75vw;
    height: 75vh;
  height: calc(var(--vh, 1vh) * 75);
    top:12.5vh;
    -webkit-transition: opacity .5s;
    -o-transition: opacity .5s;
    transition: opacity .5s;
    left:12.5vw;
    z-index: 521;
    overflow: hidden;
}
.CardShopBackgroundOuter {
    position: absolute;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100 );
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    -webkit-transition: opacity .5s;
    -o-transition: opacity .5s;
    transition: opacity .5s;
    z-index: 520;
    top:0;
    overflow: hidden;
}

.InnerShop {
    opacity: 1 !important;
    position: absolute;
    top:0
}

@-webkit-keyframes shopPop {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes shopPop {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes closeShop {
    0% {
        visibility: visible;
    }
    50% {
        visibility: visible;
    }
    100% {
        visibility: hidden;
    }
}

@keyframes closeShop {
    0% {
        visibility: visible;
    }
    50% {
        visibility: visible;
    }
    100% {
        visibility: hidden;
    }
}

@-webkit-keyframes bord-pop {

    0% {

        -webkit-transform: scale(1) translate(0,0);

                transform: scale(1) translate(0,0);

        opacity: 1;

    }
    50% {

        -webkit-transform: scale(1.9) translate(0,0);

                transform: scale(1.9) translate(0,0);

        opacity: 0.1;

    }

    100% {

        -webkit-transform: scale(1.9) translate(0,0);

                transform: scale(1.9) translate(0,0);

        opacity: 0;

    }

}

@keyframes bord-pop {

    0% {

        -webkit-transform: scale(1) translate(0,0);

                transform: scale(1) translate(0,0);

        opacity: 1;

    }
    50% {

        -webkit-transform: scale(1.9) translate(0,0);

                transform: scale(1.9) translate(0,0);

        opacity: 0.1;

    }

    100% {

        -webkit-transform: scale(1.9) translate(0,0);

                transform: scale(1.9) translate(0,0);

        opacity: 0;

    }

}

@-webkit-keyframes col {

    0% {

        -webkit-transform: scale(1) translate(0,0);

                transform: scale(1) translate(0,0);

    }

    10% {

        -webkit-transform: scale(1.1) translate(0,0);

                transform: scale(1.1) translate(0,0);

    }

    75% {

        -webkit-transform: scale(1) translate(0,0);

                transform: scale(1) translate(0,0);

    }

    100% {

        -webkit-transform: scale(1) translate(0,0);

                transform: scale(1) translate(0,0);

    }

}

@keyframes col {

    0% {

        -webkit-transform: scale(1) translate(0,0);

                transform: scale(1) translate(0,0);

    }

    10% {

        -webkit-transform: scale(1.1) translate(0,0);

                transform: scale(1.1) translate(0,0);

    }

    75% {

        -webkit-transform: scale(1) translate(0,0);

                transform: scale(1) translate(0,0);

    }

    100% {

        -webkit-transform: scale(1) translate(0,0);

                transform: scale(1) translate(0,0);

    }

}

@-webkit-keyframes bell-ring {



    5%, 15% {

        -webkit-transform: rotate(25deg);

                transform: rotate(25deg);

    }

    10%, 20% {

        -webkit-transform: rotate(-25deg);

                transform: rotate(-25deg);

    }

    25%  {

        -webkit-transform: rotate(0deg);

                transform: rotate(0deg);

    }

    100% {

        -webkit-transform: rotate(0deg);

                transform: rotate(0deg);

    }

}

@keyframes bell-ring {



    5%, 15% {

        -webkit-transform: rotate(25deg);

                transform: rotate(25deg);

    }

    10%, 20% {

        -webkit-transform: rotate(-25deg);

                transform: rotate(-25deg);

    }

    25%  {

        -webkit-transform: rotate(0deg);

                transform: rotate(0deg);

    }

    100% {

        -webkit-transform: rotate(0deg);

                transform: rotate(0deg);

    }

}
