@font-face {
    font-family: 'Aquaphonic-Dehydrated';
    src: url("./Aquaphonic-Dehydrated.otf") format("opentype");
}
.CreateAccountHeader {
    font-family: 'Aquaphonic-Dehydrated', serif;
    font-size: 10vh;
    color:#1f5171
}

.Username,.Password {
    border: none;
    position: relative;
    top:10vh;
    padding: 1vh;
    width: 10vw;
    left:5vw;
    height: 5vh;
  height: calc(var(--vh, 1vh) * 5);
    border-radius: 5vh;
}

.CreateAccountButtonSmall {
    font-size: 2vh;
    font-weight: bold;
    color: #1f5171;
    position: relative;
    top:20vh;
    width: 5vw;
    height: 5vh;
  height: calc(var(--vh, 1vh) * 5);
    border: none;
    border-radius: 5vh;
    outline: 1vh solid #ffccaa;
  outline: calc(var(--vh, 1vh) * 1) solid #ffccaa;
    background-color:#eeaa88;
    transition: transform .2s, background-color .2s;
    cursor:"none !important"
}

.CreateAccountButtonSmall:hover {
    transform: scale(1.1);
    outline: 1vh solid #ffccaa;
  outline: calc(var(--vh, 1vh) * 1) solid #ffccaa;
}


.CreateAccountButtonSmall:active {
    transform: scale(1.04);
    outline: 1vh solid #ffecca;
  outline: calc(var(--vh, 1vh) * 1) solid #ffecca;
    background-color:#fecaa8;
}

.BlueButtonSmall {
  font-size: 2vh;
  font-weight: bold;
  color: #1f5171;
  position: relative;
  top:20vh;
  width: 5vw;
  height: 5vh;
height: calc(var(--vh, 1vh) * 5);
  border: none;
  border-radius: 5vh;
  outline: 1vh solid #aaccff;
outline: calc(var(--vh, 1vh) * 1) solid #aaccff;
  background-color:#88aaee;
  transition: transform .2s, background-color .2s;
  cursor:"none !important"
}

.BlueButtonSmall:hover {
  transform: scale(1.1);
  outline: 1vh solid #aaccff;
outline: calc(var(--vh, 1vh) * 1) solid #aaccff;
}


.BlueButtonSmall:active {
  transform: scale(1.04);
  outline: 1vh solid #caecff;
outline: calc(var(--vh, 1vh) * 1) solid #caecff;
  background-color:#a8cafe;
}

.RedButtonSmall {
  font-size: 2vh;
  font-weight: bold;
  color: #1f5171;
  position: relative;
  top:20vh;
  width: 5vw;
  height: 5vh;
height: calc(var(--vh, 1vh) * 5);
  border: none;
  border-radius: 5vh;
  outline: 1vh solid #ffbbbb;
outline: calc(var(--vh, 1vh) * 1) solid #ffbbbb;
  background-color:#de8888;
  transition: transform .2s, background-color .2s;
  cursor:"none !important"
}

.RedButtonSmall:hover {
  transform: scale(1.1);
  outline: 1vh solid #ffbbbb;
outline: calc(var(--vh, 1vh) * 1) solid #ffbbbb;
}


.RedButtonSmall:active {
  transform: scale(1.04);
  outline: 1vh solid #ffdddd;
outline: calc(var(--vh, 1vh) * 1) solid #ffdddd;
  background-color:#fea8a8;
}

button:disabled:hover,
button[disabled]:hover{
    transform: scale(1.01);
    outline: 1vh solid #eeeeee;
  outline: calc(var(--vh, 1vh) * 1) solid #eeeeee;
    background-color: #cccccc;
}

.ButtonSmallDisabled {
  font-size: 2vh;
  font-weight: bold;
  color: #1f5171;
  position: relative;
  top:20vh;
  width: 5vw;
  height: 5vh;
height: calc(var(--vh, 1vh) * 5);
  border: none;
  border-radius: 5vh;
  outline: 1vh solid #eeeeee;
  outline: calc(var(--vh, 1vh) * 1) solid #eeeeee;
    background-color: #cccccc;
  transition: transform .2s, background-color .2s;
}

.ButtonSmallDisabled:hover {
  transform: scale(1.1);
    outline: 1vh solid #eeeeee;
  outline: calc(var(--vh, 1vh) * 1) solid #eeeeee;
    background-color: #cccccc;
}

.ButtonSmallDisabled:active {
  transform: scale(1.04);
  outline: 1vh solid #f8f8f8;
outline: calc(var(--vh, 1vh) * 1) solid #f8f8f8;
  background-color:#dddddd;
}