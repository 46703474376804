@keyframes smokeShow {
    0% {
        opactiy:0
    }

    100% {
        opactiy:1
    }
}
.smokeLocation {
    animation: smokeShow 2s linear;
    width: 0;
    height: 0;
    position: relative;
}
.smokeSubLocation {
    width: 18vh;
    height: 18vh;
    position: absolute;
}

.smoke {
    position: absolute;
    left:50%;top:1vh;
	width: 0px; height: 0px;
    transform: rotateX(0deg) rotateY(0deg);
}
.smoke span {
    display: block;
	position: absolute;
    left:-8vh;
	height: 0px; width: 0px;
    opacity: 0;
    transform-origin: 50% 50%;
	transform: scale(0.2);

}

@keyframes smokeLL {
	0%   { transform: scale(0.2) translate(0, 0) }
	10%  { opacity: 1; transform: scale(0.2) translate(0, -3vh) }
	100% { opacity: 0; transform: scale(1) translate(-20px, -55vh) }
}


@keyframes smokeRL {
	0%   { transform: scale(0.2) translate(0, 0) }
	10%  { opacity: 1; transform: scale(0.2) translate(0, -3vh) }
	100% { opacity: 0; transform: scale(1) translate(20px, -55vh) }
}

@keyframes smokeL {
	0%   { transform: scale(0.2) translate(0, 0) }
	10%  { opacity: 1; transform: scale(0.2) translate(0, -3vh) }
	100% { opacity: 0; transform: scale(1) translate(-20px, -45vh) }
}


@keyframes smokeR {
	0%   { transform: scale(0.2) translate(0, 0) }
	10%  { opacity: 1; transform: scale(0.2) translate(0, -3vh) }
	100% { opacity: 0; transform: scale(1) translate(20px, -45vh) }
}