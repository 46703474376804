.Tab {
    width:10vw;
    height:5vw;
}
.TabContainer {
    height: 45vh;
}
.TabBar {
    position: absolute;
    left:0;
    bottom:-5vh;
    right:0;
    height: 6rem;
    margin:auto;
}
.TabButtons {  
    background-color:#ffffffaa;
    position: absolute;
    padding-top: 2rem;
    border-radius: 3vh;
    min-width:14rem;
    width: 50vw;
    justify-content: space-around;
    display: flex;
    gap:1rem;
    left:0;
    right:0;
    margin:auto;
    height: 6rem;
}
.TabButton {
    border-radius: 15%;
    position:relative;
    width:4rem;
    height:4rem;
    margin:auto;
    top:0vh;
    font-size:2rem;
    font-weight:bold;
    border: none;
    outline:.5vh solid black; 
    transition: all .2s;
}
.TabSelected {
    color:#004488;
    outline:1vh solid #004488; 
}