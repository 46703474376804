@font-face {
    font-family: 'Aquaphonic-Dehydrated';
    src: url("./Aquaphonic-Dehydrated.otf") format("opentype");
}
.SignInHeader {
    font-family: 'Aquaphonic-Dehydrated', serif;
    font-size: 8vh;
    color:#1f5171;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.Username,.Password {
    border: none;
    position: relative;
    top:10vh;
    padding: 1vh;
    width: 10vw;
    left:5vw;
    height: 5vh;
  height: calc(var(--vh, 1vh) * 5);
    border-radius: 5vh;
}

.SignInButtonSmall {
    font-size: 2vh;
    font-weight: bold;
    color: #1f5171;
    position: relative;
    top:20vh;
    width: 5vw;
    height: 5vh;
  height: calc(var(--vh, 1vh) * 5);
    border: none;
    border-radius: 5vh;
    outline: 1vh solid #aaffcc;
  outline: calc(var(--vh, 1vh) * 1) solid #aaffcc;
    background-color:#88eeaa;
    transition: transform .2s, background-color .2s;
}

.SignInButtonSmall:hover {
    transform: scale(1.1);
    outline: 1vh solid #aaffcc;
  outline: calc(var(--vh, 1vh) * 1) solid #aaffcc;
}


.SignInButtonSmall:active {
    transform: scale(1.04);
    outline: 1vh solid #caffec;
  outline: calc(var(--vh, 1vh) * 1) solid #caffec;
    background-color:#a8feca;
}

button:disabled:hover,
button[disabled]:hover{
    transform: scale(1.01);
    outline: 1vh solid #eeeeee;
  outline: calc(var(--vh, 1vh) * 1) solid #eeeeee;
    background-color: #cccccc;
}