
.CreateAccountButton {
    font-size: 4vh;
    font-weight: bold;
    position: relative;
    left:5vw;
    width: 20vw;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    top:20vh;
    border: none;
    border-radius: 5vh;
    outline: 1vh solid #ffccaa;
  outline: calc(var(--vh, 1vh) * 1) solid #ffccaa;
    background-color:#eeaa88;
    transition: transform .2s, background-color .2s;
    color: #1f5171;
    cursor:"none !important"
}

.RedButton {
  font-size: 4vh;
  font-weight: bold;
  position: relative;
  left:5vw;
  width: 20vw;
  height: 20vh;
height: calc(var(--vh, 1vh) * 20);
  top:20vh;
  border: none;
  border-radius: 5vh;
  outline: 1vh solid #ffbbbb;
outline: calc(var(--vh, 1vh) * 1) solid #ffbbbb;
  background-color:#de8888;
  transition: transform .2s, background-color .2s;
  color: #1f5171;
  cursor:"none !important"
}

.SignInButton {
    font-size: 4vh;
    font-weight: bold;
    color: #1f5171;
    position: relative;
    left:-5vw;
    top:20vh;
    width: 20vw;
    height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
    border: none;
    border-radius: 5vh;
    outline: 1vh solid #aaffcc;
  outline: calc(var(--vh, 1vh) * 1) solid #aaffcc;
    background-color:#88eeaa;
    transition: transform .2s, background-color .2s;
    cursor:"none !important"
}

.CreateAccountButton:hover {
    transform: scale(1.1);
    outline: 1vh solid #ffccaa;
  outline: calc(var(--vh, 1vh) * 1) solid #ffccaa;
}

.RedButton:hover {
  transform: scale(1.1);
  outline: 1vh solid #ffccaa;
outline: calc(var(--vh, 1vh) * 1) solid #ffbbbb;
}

.SignInButton:hover {
    transform: scale(1.1);
    outline: 1vh solid #aaffcc;
  outline: calc(var(--vh, 1vh) * 1) solid #aaffcc;
}

.CreateAccountButton:active {
    transform: scale(1.04);
    outline: 1vh solid #ffecca;
  outline: calc(var(--vh, 1vh) * 1) solid #ffecca;
    background-color:#fecaa8;
    
}

.RedButton:active {
  transform: scale(1.04);
  outline: 1vh solid #ffdddd;
  outline: calc(var(--vh, 1vh) * 1) solid #ffdddd;
  background-color:#fea8a8;
  
}

.SignInButton:active {
    transform: scale(1.04);
    outline: 1vh solid #caffec;
  outline: calc(var(--vh, 1vh) * 1) solid #caffec;
    background-color:#a8feca;
}

button:disabled,
button[disabled]{
    outline: 1vh solid #eeeeee;
  outline: calc(var(--vh, 1vh) * 1) solid #eeeeee;
    background-color: #cccccc;
}

button:disabled:hover,
button[disabled]:hover{
    transform: scale(1.01);
    outline: 1vh solid #eeeeee;
  outline: calc(var(--vh, 1vh) * 1) solid #eeeeee;
    background-color: #cccccc;
}